import React, { Component } from "react";
import { isMobile } from "react-device-detect";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../common/spinner";
//added this

class PeopleWorkGroup extends Component {
  handleExpand = id => {
    this.setState({ [id]: "Y" });
  };

  handleShrink = id => {
    this.setState({ [id]: "N" });
  };

  boolExpanded(id) {
    try {
      if (this.state[id]) {
        // dynamic state has been expanded and is still expanded
        if (this.state[id] === "Y") return true;
        // dynamic state has been expanded then collapsed again
        if (this.state[id] === "N") return false;
      } else {
        // No dynamic state exists so collapsed by default
        return false;
      }
    } catch (e) {
      return false;
    }
  }

  backToSearchPage = () => {
    this.props.showSearchResults();
  };

  returnContent(data) {
    if (data) {
      if (data.error) {
        return <div>{this.renderError()}</div>;
      }
      return (
        <div className="contentOrg container">
          <div className="row">
            <div
              className={!isMobile ? "col" : ""}
              style={!isMobile ? { maxWidth: "14%" } : {}}
            >
              <div
                className="row backButton"
                title="Go back to search page"
                onClick={() => this.backToSearchPage()}
              >
                <div className="backButtonImage"></div>
                <div className="backButtonFont">Back</div>
              </div>
            </div>
            <hr className={isMobile ? "org-line2" : ""} />
            <div className={!isMobile ? "col" : ""}>
              {this.renderPeople(data)}
            </div>
          </div>
          <div
            className="org-BackToTopContainer"
            onClick={this.handleBackToTop}
          >
            <div className="row">
              <div className="backToTop_Org_Image">
                <div className="backToTop_Org_Text">Back to Top</div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <Spinner />;
    }
  }

  renderPeople(data) {
    let reports = data;
    return <div className="container">{this.renderReports(reports)}</div>;
  }

  getOrgChart = item => {
    const { showOrgChart, fetchOrgChartData } = this.props;
    showOrgChart(item);
    const orgResp = fetchOrgChartData(item);
    // console.log("Org chart", orgResp);
  };

  getWorkGroup = item => {
    const { showWorkGroup, fetchWorkGroupData } = this.props;
    showWorkGroup(item);
    const orgResp = fetchWorkGroupData(item.managerShortName);
    // console.log("Org chart", orgResp);
  };

  formatPhone(phone) {
    let phone_new = "";
    if (phone) {
      phone_new =
        "1-" +
        phone
          .replace(/[^\d]+/g, "")
          .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    }
    return phone_new;
  }

  formatPhoneExtenstion(item) {
    if (item.workPhoneExtension) {
      let phone_new = item.workPhoneExtension.replace(/[^\d]+/g, "");
      return ' +' + phone_new;
    } else {
      return '';
    }
  }

  renderAssistant(assistantShortName) {
    const assistantRender = assistantShortName;
    console.log("ServiceNowSearch.renderManager", assistantRender);
    this.props.handleManagerHyperlink(assistantRender);
  }

  renderManager(managerShortName) {
    const Managerrender = managerShortName;
    console.log("ServiceNowSearch.renderManager", Managerrender);
    this.props.handleManagerHyperlink(Managerrender);
  }

  getCurrentAdmins(node) {
    const { AdminNameData } = this.props;
    //let count = 0;
    console.log("node", node, AdminNameData);
    for (let i = 0; i < AdminNameData.length; i++) {
      try {
        console.log("node adminID", node.adminID, AdminNameData[i].contacts);
        console.log(
          "node adminID",
          node.adminID,
          AdminNameData[i].contacts[0].shortName
        );
        if (node.adminID === AdminNameData[i].contacts[0].shortName) {
          return this.adminNameString(AdminNameData[i].contacts[0]);
        }
      } catch {
        return "";
      }
    }
  }

  adminNameString(person) {
    let fName = person.firstName;
    if (person.prefFirstName) {
      fName = person.prefFirstName;
    }
    let lName = person.lastName;
    if (person.prefLastName) {
      lName = person.prefLastName;
    }
    return lName + ", " + fName;
  }

  renderError() {
    return (
      <React.Fragment>
        <div
          style={
            isMobile
              ? { display: "flex", marginLeft: "-70px" }
              : { display: "flex" }
          }
        >
          <div
            className="portletErrorAlert"
            style={
              isMobile
                ? {
                    marginLeft: "50px",
                    marginTop: "25px",
                    paddingTop: "5px",
                    paddingLeft: "12px"
                  }
                : { marginLeft: "50px", marginTop: "25px", paddingTop: "5px" }
            }
          >
            !
          </div>
          <div
            className="tile-apierrortext"
            style={{
              marginLeft: "10px",
              textAlign: "left",
              marginTop: "25px",
              paddingTop: "5px"
            }}
          >
            Oops, this is unexpected.
          </div>
        </div>
        <div
          className="search-apierrortext1"
          style={isMobile ? { marginLeft: "24px" } : {}}
        >
          Error code: 500
        </div>
        {isMobile ? (
          <div className="search-apierrortext1" style={{ marginLeft: "24px" }}>
            An error has occurred and <br></br>we’re working to fix the issue.
          </div>
        ) : (
          <div className="search-apierrortext1">
            An error has occurred and we’re working to fix the issue.
          </div>
        )}
        <div
          className="search-apierrortext1"
          style={isMobile ? { marginLeft: "24px" } : {}}
        >
          <span>If the issue persists please </span>
          <a
            className="tile-apierrorcontactus"
            href="https://nwproduction.service-now.com/csm"
            rel="noopener noreferrer"
            target="_blank"
          >
            contact us.
          </a>
        </div>
        <div style={{ paddingTop: "25px" }}></div>
      </React.Fragment>
    );
  }

  getChatLink(emplid, person) {
    const { orgChart_Image } = this.props;
    // NOTE, I DID A SPEED COMPARISON AND THE .find METHOD WAS
    // ACTUALLY SLOWER THAT LOOPING THROUGH THE ARRAY--BUT NOT NOTICABLY
    // UNTIL THE ARRAY HELD MILLIONS OF OBJECTS.  SO I USED THE .find
    // METHOD BECUASE IT LOOKS CLEANER (IMHO)
    let obj = orgChart_Image.find(o => o.Emplid === emplid);
    if (obj) {
      //Found the emplid in the callup_image data
      return obj.userPrincipalName;
    } else {
      //Did not find the emplid in the callup_image data
      return person.workEmail;
    }
  }

  calcTitle(person) {
    // Returns Non-Employee for contractors
    if (person.employeeType === "Non-Employee") {
      return "Non-Employee";
    } else {
      return person.jobTitle;
    }
  }

  renderReports(data) {
    const { orgChart_Image } = this.props;
    if (data) {
      return (
        <div>
          {data.map(item => (
            <div className="row">
              {/* <div className="col col-sm-1"></div> */}
              <div
                className="col md-card-people resultArticle"
                style={
                  isMobile
                    ? { marginLeft: "-20px" }
                    : { marginLeft: "8px", width: "100%" }
                }
                key={item}
                // onClick={() => this.onItemSelect(item)}
              >
                <div style={isMobile ? {} : { display: "flex" }}>
                  <div
                    style={
                      isMobile
                        ? { display: "flex" }
                        : {
                            display: "flex",
                            minWidth: "500px",
                            maxWidth: "500px"
                          }
                    }
                  >
                    {this.boolExpanded(item.emplid) ? (
                      <div onClick={() => this.handleShrink(item.emplid)}>
                        <img
                          className="expandPeople"
                          alt={"Click to collapse workgroup for " + this.buildNameString(item)}
                          src="/bolt-chevron-up.svg"
                          style={
                            isMobile
                              ? {
                                  marginLeft: "-4px",
                                  marginRight: "-10px"
                                }
                              : {}
                          }
                        />
                      </div>
                    ) : (
                      <div onClick={() => this.handleExpand(item.emplid)}>
                        <img
                          className="expandPeople"
                          alt={"Click to expand workgroup for " + this.buildNameString(item)}
                          src="/bolt-chevron-down.svg"
                          style={
                            isMobile
                              ? {
                                  marginLeft: "-4px",
                                  marginRight: "-10px"
                                }
                              : {}
                          }
                        />
                      </div>
                    )}
                    {orgChart_Image.length > 0 ? (
                      <div>
                        {this.foundImage(item.emplid) ? (
                          <img
                            className="people-image"
                            src={this.getImagefromArray(item.emplid)}
                            alt="Associate image"
                          />
                        ) : (
                          <img
                            className="people-image"
                            alt="no-image"
                            src="/no-image.svg"
                          />
                        )}
                      </div>
                    ) : (
                      <img
                        className="people-image"
                        alt="no-image"
                        src="/no-image.svg"
                      />
                    )}
                    <div>
                      <div
                        className="md-card-title articleTitle"
                        style={{ paddingTop: "2px" }}
                      >
                        {this.buildNameString(item)}
                      </div>
                      <div className="people-pronouns">
                      {item.pronouns ? item.pronouns : ""}
                     </div>
                      <div
                        className="people-subtitleNew"
                        style={
                          isMobile
                            ? { marginTop: "-8px", lineHeight: "20px" }
                            : { paddingTop: "-10px" }
                        }
                      >
                        {item.employeeType === "Non-Employee"
                          ? "Non-Employee"
                          : item.jobTitle}
                      </div>
                    </div>
                  </div>
                  <div style={isMobile ? {} : { direction: "flex" }}>
                    {item.workPhone ? (
                      <div style={isMobile ? { marginLeft: "25px" } : {}}>
                        <span className="people-phone">
                          OFFICE PHONE NUMBER:{" "}
                        </span>
                        <span className="people-subtitle">
                          {this.formatPhone(item.workPhone)}{this.formatPhoneExtenstion(item)}
                        </span>
                        <div style={{ display: "flex" }}>
                          <a
                            className="people-email"
                            href={`mailto:${item.workEmail}`}
                          >
                            <img
                              style={{
                                marginLeft: "25px",
                                marginTop: "-6px"
                              }}
                              alt="email"
                              src="/email.svg"
                            />
                            <span
                              style={{
                                paddingLeft: "4px",
                                position: "absolute",
                                marginTop: "-2px"
                              }}
                            >
                              Email
                            </span>
                          </a>
                          <a
                            className="people-chat"
                            href={`https://teams.microsoft.com/l/chat/0/0?users=${this.getChatLink(
                              item.emplid,
                              item
                            )}`}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <img
                              style={{
                                marginLeft: "25px",
                                marginTop: "-6px"
                              }}
                              alt="chat"
                              src="/chat.svg"
                            />
                            <span
                              style={{
                                paddingLeft: "4px",
                                position: "absolute",
                                marginTop: "-2px"
                              }}
                            >
                              Chat
                            </span>
                          </a>
                        </div>
                      </div>
                    ) : (
                      <div
                        style={
                          isMobile
                            ? { marginLeft: "25px", display: "flex" }
                            : { display: "flex" }
                        }
                      >
                        <a
                          className="people-email"
                          style={{ marginTop: "20px" }}
                          href={`mailto:${item.workEmail}`}
                        >
                          <img
                            style={{
                              marginLeft: "25px",
                              marginTop: "-6px"
                            }}
                            alt="email"
                            src="/email.svg"
                          />
                          <span
                            style={{
                              paddingLeft: "4px",
                              position: "absolute",
                              marginTop: "-2px"
                            }}
                          >
                            Email
                          </span>
                        </a>
                        <a
                          className="people-chat"
                          href={`https://teams.microsoft.com/l/chat/0/0?users=${item.workEmail}`}
                          rel="noopener noreferrer"
                          target="_blank"
                          style={{ marginTop: "20px" }}
                        >
                          <img
                            style={{
                              marginLeft: "25px",
                              marginTop: "-6px"
                            }}
                            alt="chat"
                            src="/chat.svg"
                          />
                          <span
                            style={{
                              paddingLeft: "4px",
                              position: "absolute",
                              marginTop: "-2px"
                            }}
                          >
                            Chat
                          </span>
                        </a>
                      </div>
                    )}
                  </div>
                </div>
                {this.boolExpanded(item.emplid) ? (
                  <div
                    className="peopleexpandLine"
                    style={isMobile ? {} : { display: "flex" }}
                  >
                    <div
                      style={
                        isMobile
                          ? {}
                          : {
                              minWidth: "440px",
                              maxWidth: "440px",
                              marginLeft: "60px",
                              paddingTop: "10px"
                            }
                      }
                    >
                      <div>
                        <span className="people-phone">SHORTNAME: </span>
                        <span className="people-subtitle">
                          {item.shortName}
                        </span>
                      </div>
                      <div>
                        <span className="people-phone">WORK GROUP: </span>
                        <span className="people-subtitle">
                          {item.department}
                        </span>
                      </div>
                      <div>
                        <span className="people-phone">REPORTS TO: </span>
                        <span
                          className="people-subtitle"
                          onClick={() =>
                            this.renderManager(item.managerShortName)
                          }
                          style={{
                            textDecoration: "underline",
                            color: "#1C57A5",
                            cursor: "pointer"
                          }}
                        >
                          {/* {item.managerLastName}, {item.managerFirstName} */}
                          {item.managerPrefName}
                        </span>
                      </div>
                      {item.adminID ? (
                        <div>
                          <span className="people-phone">
                            ADMINISTRATIVE ASSISTANT:{" "}
                          </span>
                          <span
                            className="people-subtitle"
                            style={{
                              textDecoration: "underline",
                              color: "#1C57A5",
                              cursor: "pointer"
                            }}
                            onClick={() => this.renderAssistant(item.adminID)}
                          >
                            {this.getCurrentAdmins(item)}
                          </span>
                        </div>
                      ) : (
                        <div> </div>
                      )}
                      <div
                        style={
                          isMobile
                            ? { marginLeft: "25px", display: "flex" }
                            : { display: "flex" }
                        }
                      >
                        <a
                          className="people-workGroup"
                          // href={"/orgchart"}
                          rel="noopener noreferrer"
                          target="_blank"
                          title="Open Work Group"
                          style={{ marginTop: "20px" }}
                          onClick={() => this.getWorkGroup(item)}
                        >
                          <img
                            style={{
                              marginLeft: "12px",
                              marginTop: "-6px"
                            }}
                            alt="Work Group"
                            src="/work-group-icon.svg"
                          />
                          <span
                            style={{
                              paddingLeft: "6px",
                              position: "absolute",
                              marginTop: "-2px"
                            }}
                          >
                            Work Group
                          </span>
                        </a>
                        <a
                          className="people-org"
                          // href={'/orgchart'}
                          rel="noopener noreferrer"
                          target="_blank"
                          style={{ marginTop: "20px" }}
                          title="Open OrgChart"
                          onClick={() => this.getOrgChart(item)}
                        >
                          <img
                            style={{
                              marginLeft: "12px",
                              marginTop: "-6px"
                            }}
                            alt="Org Chart"
                            src="/org-chart-icon.svg"
                          />
                          <span
                            style={{
                              color: "#1c57a5",
                              paddingLeft: "6px",
                              position: "absolute",
                              marginTop: "-2px"
                            }}
                          >
                            Org Chart
                          </span>
                        </a>
                      </div>
                    </div>
                    <div style={isMobile ? {} : { paddingTop: "10px" }}>
                      <div>
                        <span className="people-phone">MAIL CODE: </span>
                        <span className="people-subtitle">{item.mailDrop}</span>
                      </div>
                      <div>
                        <span className="people-phone">
                          DISBURSEMENT CODE:{" "}
                        </span>
                        <span className="people-subtitle">{item.disbCode}</span>
                      </div>
                      <div>
                        <span className="people-phone">
                          HR BUSINESS LOCATION (LOCATION ID):{" "}
                        </span>
                      </div>
                      <div className="people-subtitle">{item.locName}</div>
                      <div className="people-subtitle">
                        {item.state}
                        {", "}
                        {item.postal}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          ))}
        </div>
      );
    }
  }

  handleBackToTop = () => {
    document.body.scrolltop = 0;
    document.documentElement.scrollTop = 0;
  };

  buildNameString(person) {
    let dataRow = person;
    let output = "";
    
    if (
      dataRow.prefFirstName &&
      dataRow.prefFirstName.replace(" ", "") !== ""
    ) {
      output += dataRow.prefFirstName + " ";
    } else {
      output += dataRow.firstName + " ";
    }
    if (dataRow.prefLastName && dataRow.prefLastName.replace(" ", "") !== "") {
      output += dataRow.prefLastName + " ";
    } else {
      output += dataRow.lastName + " ";
    }

    return output;
  }
  foundImage(emplid) {
    if (this.props.orgChart_Image.length > 0) {
      let i = this.props.orgChart_Image.length;
      for (var j = 0; j < i; j++) {
        if (emplid === this.props.orgChart_Image[j].Emplid) {
          if (this.props.orgChart_Image[j].Image) {
            if (this.props.orgChart_Image[j].Image !== "") {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  getImagefromArray(emplid) {
    if (this.props.orgChart_Image.length > 0) {
      let i = this.props.orgChart_Image.length;
      for (var j = 0; j < i; j++) {
        if (emplid === this.props.orgChart_Image[j].Emplid) {
          let returnvar =
            "data:image/jpeg;base64," + this.props.orgChart_Image[j].Image;
          if (returnvar) {
            return returnvar;
          }
        }
      }
    }
  }

  render() {
    const { WorkGroupData } = this.props;

    return (
      <div className="orgBackground">
        <div className="orgChartContainer">
          <div className="container">
            <div className="row" style={{ paddingLeft: "35px" }}>
              <div className="searchTitle">Workgroup for:</div>
              <div className="searchText">
                {this.buildNameString(this.props.workGroupDisplay)}
              </div>
            </div>
            <hr className="org-line" />
          </div>
          {this.returnContent(WorkGroupData)}
        </div>
      </div>
    );
  }
}

export default PeopleWorkGroup;
